// noinspection DuplicatedCode

import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';
import { getBookingStatus, getBookingStatusColor } from 'src/app/shared/mappings/mappings';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { SearchForm } from 'src/app/shared/models/elastic.vm.model';
import Helpers from 'src/app/shared/utils/helpers';
import { AuthSelectors } from '../../auth/store';
import { getLocationAddress, getPriceType, mapQueryParamsToPage } from '../../shared/mappings/mappings';
import { Area } from '../../shared/models/area.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { RiderDetailsVM, RiderListVM, RiderTripListVM } from '../../shared/models/rider.vm.model';
import { FirestoreSelector } from '../../store/firestore';
import { SupportAgentState } from '../../store/support-agent.state';

export const ridersState = (state: SupportAgentState) => state.ridersState;

export const isUserSignedIn = createSelector(AuthSelectors.signedInSelector, isSignedIn => isSignedIn);

export const storagePageSizeSelector = createSelector(
  AuthSelectors.browserStorageSelector,
  storage => storage?.find(item => item.key === 'pageSize:supportAgent:riders')?.value,
);

export const areasSelector = createSelector(FirestoreSelector.areaListSelector, areas => areas);

export const ridersSelector = createSelector(ridersState, state => state.riders);

export const queryParamsSelector = createSelector(ridersState, state => state.queryParams);

export const searchResponseSelector = createSelector(ridersState, state => state.searchResponse);

export const isLoadingSelector = createSelector(ridersState, state => state.isLoading);

export const riderIdSelector = createSelector(ridersState, state => state.riderId);

export const riderSelector = createSelector(ridersState, state => state.rider);

export const tripSearchResponseSelector = createSelector(ridersState, state => state.tripSearchResponse);

export const riderTripsSelector = createSelector(ridersState, state => state.trips);

export const userPageSizeSelector = createSelector(ridersState, state => state.userPageSize);

export const riderListSelector = createSelector(ridersSelector, riders =>
  riders ? mapRiderAggrToRiderList(riders) : null,
);

function mapRiderAggrToRiderList(riders: RiderAggregate[]): RiderListVM[] {
  return riders.map(d => ({
    id: d.rider_id,
    title: d.rider ? [d.rider.first_name, d.rider.last_name].join(' ') : null,
    email: d.firebase_user?.email,
    phone: d.firebase_user?.phone,
    emailVerified: d.firebase_user?.email_verified,
    disabled: d.firebase_user?.disabled,
    firstName: d.rider?.first_name,
    lastName: d.rider?.last_name,
    createdAt: d.rider?.created_at,
  }));
}

export const riderDetailsVMSelector = createSelector(riderSelector, rider =>
  rider ? mapRiderAggrToRiderDetailsVM(rider) : null,
);

function mapRiderAggrToRiderDetailsVM(aggr: RiderAggregate): RiderDetailsVM {
  return {
    id: aggr.rider_id,
    email: aggr.firebase_user?.email,
    phone: aggr.firebase_user?.phone,
    language: aggr.rider?.language,
    emailVerified: aggr.firebase_user?.email_verified,
    disabled: aggr.firebase_user?.disabled,
    firstName: aggr.rider?.first_name,
    lastName: aggr.rider?.last_name,
    createdAt: aggr.rider?.created_at,
    markedForDeletionAt: aggr.rider?.marked_for_deletion_at,
    workProfileReceiptEmail: aggr.rider?.work_profile?.receipt_email,
    workProfileReceiptEmailDomain: aggr.rider?.work_profile?.receipt_email_domain,
  };
}

export const searchResponseIdsSelector = createSelector(
  searchResponseSelector,
  // eslint-disable-next-line no-underscore-dangle
  response => (response ? response.hits?.hits?.map(hit => hit._id) : []),
);

export const tripSearchResponseIdsSelector = createSelector(
  tripSearchResponseSelector,
  // eslint-disable-next-line no-underscore-dangle
  response => (response ? response.hits?.hits?.map(hit => hit._id) : []),
);

export const riderTripListSelector = createSelector(riderTripsSelector, areasSelector, (bookings, areas) =>
  bookings ? mapBookingsToTripList(bookings, areas) : null,
);

function mapBookingsToTripList(bookings: BookingAggregate[], areas: Area[]): RiderTripListVM[] {
  return bookings.map(aggregate => mapBooking(aggregate, areas));
}

function mapBooking(aggregate: BookingAggregate, areas: Area[]): RiderTripListVM {
  const booking = aggregate.booking;
  const trip = aggregate.trip;
  const vehicleLicensePlate = trip?.vehicle?.license_plate;
  const tripPrice = aggregate.trip_price;

  const pickup =
    trip && trip.pickup
      ? getLocationAddress(trip.pickup)
      : booking && booking.pickup
        ? getLocationAddress(booking.pickup)
        : '';

  const dropoff =
    trip && trip.dropoff
      ? getLocationAddress(trip.dropoff)
      : booking && booking.dropoff
        ? getLocationAddress(booking.dropoff)
        : '';

  const driverFullName = trip?.driver != null ? trip.driver.first_name + ' ' + trip.driver.last_name : '';

  return {
    bookingId: aggregate.doc_id,
    createdAt: booking.created_at,
    pickupAt: booking.pickup_at,
    pickup,
    dropoff,
    driverFullName,
    vehicleLicensePlate,
    totalPrice: tripPrice?.customer_net_amount?.display,
    priceType: tripPrice ? getPriceType(tripPrice.type) : '',
    timeZoneId: mapBookingAggrToAreaTimeZone(areas, booking.area_id),
    rating: aggregate.driver_rating?.rating,
    status: getBookingStatus(aggregate),
    statusColorClass: getBookingStatusColor(getBookingStatus(aggregate)),
  };
}

function mapBookingAggrToAreaTimeZone(areas: Area[], areaId: string) {
  return areas.find(area => area.id === areaId)?.time_zone_id;
}

export const totalRecordsSelector = createSelector(searchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);

export const listPageSize = createSelector(storagePageSizeSelector, userPageSizeSelector, (storage, state) =>
  !state ? (!storage ? 10 : storage) : state,
);

export const pageSelector = createSelector(queryParamsSelector, listPageSize, (params, pageSize) =>
  mapQueryParamsToPage(params, pageSize),
);

export const pageSizeSelector = createSelector(pageSelector, page => page.pageSize);

export const pageIndexSelector = createSelector(pageSelector, page => page.pageIndex);

export const searchFormSelector = createSelector(queryParamsSelector, params =>
  params ? mapQueryParamsToSearchForm(params) : null,
);

function mapQueryParamsToSearchForm(params: Params): SearchForm {
  if (params.query) {
    return Helpers.decode<SearchForm>(params.query);
  }
}

export const tripSearchResponseTotalRecords = createSelector(tripSearchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);
